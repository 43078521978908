import stateMaker from "../Utils/stateMaker"

const UserReducer = (state = { account: {} }, action) => {
    switch (action.type) {
        case "LOGIN":
            return stateMaker(state, "account", "fetch", action)
        case "LOGOUT":
            return { account: {} }
        case "TEST_TOKEN":
            return state
        case "SET_ACCOUNT": {
            const payload = action.payload
            const new_state = { account: { ...payload } }
            return new_state
        }
        default:
            return state
    }
}

export default UserReducer
